<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field label="事業單位名稱" 
                                        v-model="siteData.orgName"
                                        outlined 
                                        hide-details
                                        readonly>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-10">
                                    <v-text-field label="場域名稱" 
                                        v-model="siteData.jsName"
                                        outlined 
                                        hide-details
                                        readonly>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10">
                                     <v-select
                                        v-model="notificationText"
                                        :items="notificationStatusItems"
                                        hide-details
                                        label="通知狀態"
                                        single-line
                                    ></v-select>
                                </v-col>
                                <v-col class="px-10">
                                    <v-select
                                        v-model="participateText"
                                        :items="participateStatusItems"
                                        hide-details
                                        label="參加狀態"
                                        single-line
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row class="mt-10"  justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitMail">
                                        發送email通知
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitSMS">
                                        發送簡訊通知
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                                    <v-btn @click="showSelect" v-if="false">test</v-btn>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-data-table
                                        v-model="tableSelected"
                                        :headers="headers"
                                        :items="tableDataList"
                                        item-key="orgmId"
                                        hide-default-footer
                                        disable-sort
                                        show-select
                                        class="mx-12 mb-12"> 
                                        <!--
                                        <template v-slot:[`header.participateStatus`]>
                                            <v-select
                                                :items="participateStatusItems"
                                                solo
                                                dense
                                                hide-details
                                                label="參加狀態"
                                            ></v-select>
                                        </template>
                                        -->
                                       <template v-slot:[`item.history`]={item}>
                                            <v-icon @click.stop="showDetail(item)" >mdi-format-list-bulleted-square</v-icon>
                                        </template>
                                    </v-data-table>

                                </v-col>
                            </v-row>
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
    </v-container>
    <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import loadingMask from '@/components/public/loadingMask.vue'
import commonMixin from '@/utils/commonMixin';
import {getJobSiteData, getActivityNotificationList, sendNotification, getNotificationRecordList} from "@/api/hyirecoApi";

export default {
  name: 'orgnizationList',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
        
  },
  destroyed() {
    clearInterval(this.interval);
  },
  mounted(){
    console.log("mounted");
    this.getSiteData();
    this.getData();
  },
  methods: { 
    getSiteData: function(){
        this.isLoading = true;
        var vm = this;
        getJobSiteData(vm.$route.params.jsId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("responseData = " + JSON.stringify(responseData));

                vm.siteData.jsName = responseData.jobSite.jsName;
                vm.siteData.orgName = responseData.jobSite.orgnization.name;
            }
        });
        this.isLoading = false;
    },
    getData: function(){
      this.isLoading = true;
      var vm = this;
      getActivityNotificationList(vm.$route.params.jsId, vm.auth).then(function(response) {
        if ("200" == response.status) { 
            var responseData = response.data;
            //console.log("responseData = " + JSON.stringify(responseData));

            // orgnizationMember
            // notificationStatus
            // orgnizationMember.name
            // orgnizationMember.email
            // orgnizationMember.mobilePhone
            vm.datalist = responseData.activityNotificationList.map(function(obj){
                var item = new Object();
                item.anId = obj.anId;
                item.jsId = obj.jsId;
                item.orgmId = obj.orgnizationMember.orgmId;
                item.participateStatus = obj.participateStatus;
                item.participateText = obj.participateStatus === "Y" ? "參加" : "取消";
                item.notificationStatus = obj.notificationStatus;
                item.notificationText = obj.notificationStatus ==="Y" ? "已發送" : "";
                item.name = obj.orgnizationMember.name;
                item.email = obj.orgnizationMember.email;
                item.mobilePhone = obj.orgnizationMember.mobilePhone;
                item.orgnizationMember = obj.orgnizationMember;
                
                return item;
            });

            console.log("dataList = " + JSON.stringify(vm.datalist));

            vm.tableDataList = JSON.parse(JSON.stringify(vm.datalist));
            vm.tableSelected = vm.tableDataList;
            //console.log("activityNotificationList = " + JSON.stringify(responseData.activityNotificationList));
        }
      });
      this.isLoading = false;
    },
    showSelect: function() {
        console.log(this.tableSelected);
    },
    filterDataList: function() {
        let vm = this;
        vm.tableDataList = JSON.parse(JSON.stringify(vm.datalist));

        if (vm.notificationText !== "通知狀態") {
            vm.tableDataList = vm.tableDataList.filter(function(item){
                var flag = vm.notificationText == "未發送" ? "" : vm.notificationText;
                return item.notificationText == flag;
            });
        }

        if (vm.participateText !== "參加狀態") {
            vm.tableDataList = vm.tableDataList.filter(function(item){
                return item.participateText == vm.participateText;
            });
        }

        vm.tableSelected = vm.tableDataList;
    },
    submitMail: function(){this.toSendMassage("E");},
    submitSMS: function(){this.toSendMassage("M");},
    toCancel: function(){this.$router.push({ path: "/jobSite/jobSiteList" });},
    showDetail: function(item){
        var vm = this;
        vm.isLoading = true;
        getNotificationRecordList(item.anId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                console.log("responseData = " + JSON.stringify(responseData));
                var alertHtml = "";
                alertHtml += '<table align="left" border="0" cellpadding="0" cellspacing="0" style="width:100%">';
                alertHtml += '<thead>';
                alertHtml += '    <tr>';
                alertHtml += '        <th scope="col" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">寄送方式</th>';
                alertHtml += '        <th scope="col" style="border-bottom: thin solid rgba(0, 0, 0, 0.12);">寄送時間</th>';
                alertHtml += '    </tr>';
                alertHtml += '</thead>';
                alertHtml += '<tbody>';
                responseData.notificationRecordList.forEach(function(item){
                    var mode = "";
                    switch (item.notificationMode) {
                        case 'E':
                            mode = "email";
                            break;
                        case 'M':
                            mode = "簡訊";
                            break;
                        default:
                            mode = item.notificationMode;
                            break;
                    }

                    alertHtml += ' <tr>';
                    alertHtml += '    <td style="text-align:center;border-bottom: thin solid rgba(0, 0, 0, 0.12);font-size: 0.875rem;height: 48px;">'+ mode +'</td>';
                    alertHtml += '    <td style="text-align:center;border-bottom: thin solid rgba(0, 0, 0, 0.12);font-size: 0.875rem;height: 48px;">'+ item.sendDate +'</td>';
                    alertHtml += ' </tr>';
                });
                alertHtml += '</tbody>';
                alertHtml += '</table>';
                vm.$swal.fire({
                    title: '<strong>發送紀錄</strong>',
                    html:
                        '<div style="width:100%;height:400px;overflow:auto;">' + alertHtml + '</div>',
                    showCloseButton: true,
                    showConfirmButton: false,
                });
            }
            vm.isLoading = false;
        });
    },
    toSendMassage: function(sendWay){
        var vm = this;
        vm.isLoading = true;
        var mode = [];
        mode.push(sendWay);

        var dataObj = new Object();
        dataObj.notificationModeList = mode;
        dataObj.activityNotificationList = vm.tableSelected;

        //console.log("dataObj = " + JSON.stringify(dataObj));
        sendNotification(dataObj, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                //console.log("responseData = " + JSON.stringify(responseData));
                vm.$swal(responseData.message);
            }
            vm.isLoading = false;
        }).catch((error) => { 
            console.error(error); 
            vm.isLoading = false;
        });
        
    },
  },
  watch: {
      notificationText: function() {
          this.filterDataList();
      },
      participateText: function() {
          this.filterDataList();
      }
  },
   computed: {
    headers() {
      return [
          {
            text: '參加狀態',
            align: 'left',
            value: 'participateText',
            class: "subtitle-2 font-weight-black",
            width: 100,
          },
          { text: '通知狀態', value: 'notificationText', class: "subtitle-2 font-weight-black",width:100,},
          { text: '通知記錄', value: 'history', class: "subtitle-2 font-weight-black",width:100,},
          { text: '姓名', value: 'name', class: "subtitle-2 font-weight-black",width:150,},
          { text: 'email', value: 'email', class: "subtitle-2 font-weight-black"},
          { text: '手機', value: 'mobilePhone', class: "subtitle-2 font-weight-black",width:170,},
      ]
    },
  },
  data: () => ({
    userName: "",
    isLoading: false, //loading畫面的開關
    auth: {},
    datalist: [],
    tableDataList: [],
    siteData:{
        jsName: "",
        orgName: "",
    },
    participateStatusItems: ['參加狀態', '參加', '取消'],
    participateText: "參加狀態",
    notificationStatusItems: ['通知狀態','已發送','未發送'],
    notificationText: "通知狀態",
    notificationSendItems:['通知方式','email','簡訊'],
    tableSelected: [],
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>

</style>